import React, { FC } from 'react'

const Play: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg {...{ className }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4 12v8h16V4H4v8zm1.194 0v6.806h13.612V5.194H5.194V12zm3.981 0c0 2.431.007 4.417.016 4.414.05-.02 6.847-4.402 6.847-4.414 0-.012-6.797-4.394-6.847-4.414-.009-.003-.016 1.983-.016 4.414zm1.195 0c0 1.786.005 2.236.023 2.226.14-.08 3.446-2.215 3.446-2.226 0-.011-3.305-2.146-3.446-2.226-.019-.01-.023.44-.023 2.226z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default Play
