export const flatSections = [
  { id: 'ai-agents', title: 'Our AI Agents in the Jobs Vertical' },
  { id: 'the-problem', title: 'The Problem' },
  { id: 'key-features', title: 'Key Features' },
  { id: 'page-understanding-and-grounding', title: 'Page Understanding and Grounding' },
  { id: 'comprehensive-interaction-capabilities', title: 'Comprehensive Interaction Capabilities' },
  { id: 'tactical-and-strategic-reasoning', title: 'Tactical and Strategic Reasoning' },
  { id: 'adaption-and-resilience', title: 'Adaptation and Resilience' },
  { id: 'vertical-design-with-generalizability', title: 'Vertical Design with Generalizability' },
  { id: 'future-direction', title: 'Future Direction' },
]
