import { JobPageItem, JobSearch } from '@types'

import { sendEventToAnalytics } from '@lib/helpers/client'

export function emailDailyRecommendationsRedirectEvent(search: JobSearch, dailyApply: number, job?: JobPageItem): void {
  const eventName = `Email:daily_recommendations:apply_button:${dailyApply}`
  const payload = {
    jobId: job?.id || '',
    searchMicroRole: search.job.micro,
    searchMacroRole: search.job.macro || '',
    searchLocationName: search.location.name,
    searchLocationType: search.location.type,
    searchLocationId: search.location.id,
  }
  sendEventToAnalytics(eventName, payload)
}

export function emailInstantJobMatchMoreJobsButtonClickedEvent(search: JobSearch): void {
  const eventName = 'Email:instant_job_match:more_jobs_like_this_button'
  const payload = {
    searchMicroRole: search.job.micro,
    searchMacroRole: search.job.macro || '',
    searchLocationName: search.location.name || '',
    searchLocationType: search.location.type || '',
    searchLocationId: search.location.id || '',
  }
  sendEventToAnalytics(eventName, payload)
}

export function emailInstantJobMatchViewAllButtonClickedEvent(search: JobSearch): void {
  const eventName = `Email:daily_recommendations:view_all_button:${search.viewAllJobs}`
  const payload = {
    searchMicroRole: search.job.micro || '',
    searchMacroRole: search.job.macro || '',
    searchLocationName: search.location.name || '',
    searchLocationType: search.location.type || '',
    searchLocationId: search.location.id || '',
  }
  sendEventToAnalytics(eventName, payload)
}

export function emailOptOutEvent(campaigns: string[]): void {
  const eventName = 'web_email_opt_out'
  const payload = { campaigns }
  sendEventToAnalytics(eventName, payload)
}
