'use client'

import { FC } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'
import s from './images.module.scss'
import 'swiper/css'
import 'swiper/css/pagination'
import { Pagination, A11y } from 'swiper/modules'
import Video from '../video'

const Images: FC = () => {
  const breakpoints = {
    768: {
      slidesPerView: 2,
      spaceBetween: 24,
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 24,
    },
  }
  const _breakpoints = {
    0: { slidesPerView: 1, spaceBetween: 0 },
    ...breakpoints,
  }
  const spaceBetween = 24

  const styles = Object.entries(_breakpoints)
    .map(([key, value]) => {
      const { slidesPerView = 1, spaceBetween: gap = spaceBetween } = value
      return `
       @media (min-width: ${key}px) {
          .swiper-slide {
            width: calc((100% - ${(slidesPerView - 1) * gap}px) / ${slidesPerView});
            margin-right: ${gap}px;
          }
        }`
    })
    .join(' ')
    .replace(/\s+/g, ' ')
  return (
    <>
      <style>{styles}</style>
      <Swiper
        id="video-slide"
        className={s.root}
        modules={[Pagination, A11y]}
        slidesPerView={1}
        spaceBetween={spaceBetween}
        breakpoints={breakpoints}
        navigation
        pagination={{ clickable: true }}
        loop={false}
        style={{
          width: '100%',
          height: 'auto',
        }}
      >
        <SwiperSlide>
          <div className={s['image-wrapper']}>
            <Video
              description="The SonicJobs AI Agent performs an analysis of the job application flow and produces a report about its stages, the UI controls involved and screening questions"
              video="RobotEngineReport"
              altText="SonicJobs AI Agent Report"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={s['image-wrapper']}>
            <Video
              description="A job application on the SonicJobs website with the screening questions previously collected by the AI Agent asked to the candidate by the chatbot Julie"
              video="Julie"
              altText="SonicJobs Julie"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={s['image-wrapper']}>
            <Video
              description="The job application being executed by the AI Agent with the candidate's data previously collected by Julie"
              video="Alice&Rob"
              altText="SonicJobs AI Agent Job Application"
            />
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  )
}

export default Images
