'use client'

import { FC } from 'react'

import cn from 'clsx'

import s from './sidebar.module.scss'
import { flatSections } from '../sections'
const Y_OFFSET = -140

const tree: Array<{ id: string; title: string; children?: string[] }> = [
  { id: 'ai-agents', title: 'Our AI Agents in the Jobs Vertical' },
  { id: 'the-problem', title: 'The Problem' },
  {
    id: 'key-features',
    title: 'Key Features',
    children: [
      'page-understanding-and-grounding',
      'comprehensive-interaction-capabilities',
      'tactical-and-strategic-reasoning',
      'adaption-and-resilience',
      'vertical-design-with-generalizability',
    ],
  },
  { id: 'future-direction', title: 'Future Direction' },
]

const scrollToSection = (id: string): void => {
  const element = document.getElementById(id)
  if (element) {
    const y = element.getBoundingClientRect().top + window.scrollY + Y_OFFSET
    window.scrollTo({ top: y, behavior: 'smooth' })
  }
}

const SidebarItem: FC<{ id: string; title: string; subs?: string[]; activeSection: string; level: number }> = ({
  id,
  title,
  subs = [],
  activeSection,
  level,
}) => {
  const isActive = activeSection === id
  const hasChildren = subs && subs.length > 0

  const hasActiveChild = subs.some((child) => child === activeSection)
  const liCn = cn(
    s.li,
    s[`level-${level}`],
    { [s['has-active']]: hasActiveChild },
    { [s.active]: isActive },
    { [s['has-children']]: hasChildren },
  )

  const linkCn = cn(s.link, { [s.active]: activeSection === id })
  return (
    <li key={id} className={liCn} data-active={isActive ? 1 : 0} data-has-active={hasActiveChild ? 1 : 0}>
      <a
        className={linkCn}
        href={`#${id}`}
        onClick={(e) => {
          e.preventDefault()
          scrollToSection(id)
        }}
      >
        {title}
      </a>
      {hasChildren && (
        <ul className={s.sublist}>
          {subs.map((child) => {
            const childSection = flatSections.find((s) => s.id === child)
            if (!childSection) return null

            return (
              <SidebarItem
                key={childSection.id}
                id={childSection.id}
                title={childSection.title}
                activeSection={activeSection}
                level={level + 1}
              />
            )
          })}
        </ul>
      )}
    </li>
  )
}
const Sidebar: FC<{ activeSection: string }> = ({ activeSection }) => {
  return (
    <aside className={s.sidebar}>
      <nav className={s.nav}>
        <ul className={s.list}>
          {tree.map((section) => (
            <SidebarItem
              key={section.id}
              id={section.id}
              title={section.title}
              subs={section.children ?? []}
              activeSection={activeSection}
              level={1}
            />
          ))}
        </ul>
      </nav>
    </aside>
  )
}

export default Sidebar
