'use client'

import { FC, useEffect, useState } from 'react'

import Sidebar from './sidebar'

import s from './info.module.scss'
import { flatSections } from './sections'

export const Y_OFFSET = -140

const Info: FC = () => {
  const [activeSection, setActiveSection] = useState('ai-agent')

  useEffect(() => {
    const handleScroll = (): void => {
      const scrollPosition = window.scrollY

      const active = flatSections.find((section) => {
        const element = document.getElementById(section.id)
        if (element) {
          const { offsetTop, offsetHeight } = element
          return scrollPosition >= offsetTop + Y_OFFSET && scrollPosition < offsetTop + offsetHeight + Y_OFFSET
        }
        return false
      })

      if (active && active.id !== activeSection) {
        setActiveSection(active.id)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return (): void => window.removeEventListener('scroll', handleScroll)
  }, [activeSection])

  return (
    <div className={s.root}>
      <Sidebar activeSection={activeSection} />
      {/* Main content */}
      <div>
        <section className={s.section} id="ai-agents">
          <p>
            The jobs vertical is the perfect use case because it is highly fragmented, involves complex job application
            flows, has limited tech resource allocation within companies, requires highly accurate and resilient
            outcomes, and impacts the lives of millions of companies and individuals every day.
          </p>
          <h3 className={s.title}>Our AI Agents in the Jobs Vertical</h3>
          <p>Our AI Agents enables the user to apply for a job seamlessly on any site, by:</p>
          <ol>
            <li>
              Autonomously understanding the input fields required within the job application flow on the career site,
              regardless of page layout, input types, or applicant tracking system;
            </li>
            <li>Asking the required questions seamlessly on-site, without redirecting the candidate;</li>
            <li>Using these responses to complete the application on the career site on behalf of the candidate;</li>
            <li>Tracking when a job application has been completed successfully.</li>
          </ol>
          <p>
            This is done by leveraging proprietary Computer Vision and Natural Language Understanding models, providing
            a flexible, robust and scalable solution.
          </p>
        </section>
        <section className={s.section} id="the-problem">
          <h4 className={s.subtitle}>The Problem</h4>
          <p>
            Candidates applying for a job today still have to go through an experience that resembles the early stages
            of the Internet:
          </p>
          <ol>
            <li>They have to perform a search on a job website;</li>
            <li>When they find a job they like and click on it, they get redirected to the company career site;</li>
            <li>They are asked to set up a profile by filling in their personal details;</li>
            <li>They are asked screening questions specific to the job;</li>
            <li>Only at this stage are they able to complete the job application.</li>
          </ol>
          <p>Candidates hate this experience, and 95% of them drop out before completing the application.</p>
        </section>
        <section className={s.section} id="key-features">
          <h4 className={s.subtitle}>Key Features</h4>
          <ul className={s.list}>
            <li>
              Page Understanding and Grounding: web pages come with varied and complex sets of UI controls, which are
              interpreted, classified and grouped utilizing both visual appearance and page source code.
            </li>
            <li>
              Comprehensive Interaction Capabilities: the interaction with UI controls and widgets, often a tricky and
              non-trivial task, exploits a repertoire of diverse techniques, including classical and visual-based
              automation tasks.
            </li>
            <li>
              Tactical and Strategic Reasoning: at each step of a job application, the most proper action to be
              undertaken on a web page is evaluated dynamically, according to the local context as well as user profile
              and navigation history.
            </li>
            <li>
              Adaptation and Resilience: the highly varying and fast-evolving landscape of job application web flows is
              properly addressed thanks to an outstandingly flexible and self-adapting architecture.
            </li>
            <li>
              Vertical Design Generalizability: SonicJobs utilizes a set of proprietary models, specifically designed
              and trained in-house, exploiting a broad range of vision and language techniques to ensure precise and
              context-aware processing of web pages in the jobs vertical, while being flexible enough to expand into new
              verticals.
            </li>
          </ul>
        </section>
        <section className={s.section} id="page-understanding-and-grounding">
          <h5 className={s['boxed-title']}>Page Understanding and Grounding</h5>
          <p>
            A single web page has hundreds of elements. Our AI Agents combine advanced Machine Learning techniques to
            hande the complex task of web page interpretation. Task predictions are performed through state-of-the-art
            Computer Vision and fused with the output of HTML analysis and Optical Character Recognition to yield a
            solid grounding of UI detections.
          </p>
          <p>
            SonicJobs’ proprietary models are trained with a combination of Supervised, Unsupervised and Self-Supervised
            techniques, and leverage a continuously growing amount of proprietary real-world data coming from our
            production environment, along with synthetic data generated using in-house technology.
          </p>
        </section>
        <section className={s.section} id="comprehensive-interaction-capabilities">
          <h5 className={s['boxed-title']}>Comprehensive Interaction Capabilities</h5>
          <p>
            Input UI controls and widgets on web pages exhibit great variability and a broad range of implementation
            options, including custom-made date pickers, dynamically-populated lists, overlays and pop-ups. Our AI
            Agents understand these input controls and interact with them, which is a very challenging task.
          </p>
          <p>
            The AI Agents are able to leverage the HTML structure of an input control, as well as its visual aspect and
            any surrounding readable information, to pick the most suitable technique to interact with it using a vast
            repertoire of options. The range of techniques guarantees ample coverage of the interaction patterns
            encountered in web pages.
          </p>
        </section>
        <section className={s.section} id="tactical-and-strategic-reasoning">
          <h5 className={s['boxed-title']}>Tactical and Strategic Reasoning</h5>
          <p>
            Building upon a deep domain-grounded understanding of web pages, the AI Agents identify the most proper set
            of actions to be undertaken in order to advance the job application flow, filtering elements on the page
            that are not required. This includes understanding screening questions, resume upload, and clicking on the
            ‘Next’ and “Apply” buttons, to progress along multiple pages within the job application flow.
          </p>
          <p>
            If the outcome of the chosen action is not what is expected, a buddy Agent specialized on error detection
            advises on correction or recovery measures to allow the main Agent to proceed with the flow.
          </p>
          <p>
            Moreover, the AI Agents implement a Strategic Reasoning level, which affects the navigation flow from a
            broader perspective, allowing the Agent to try alternative paths to maximize success. An example of
            Strategic Reasoning is the capability of the Agent to deal with conditional questions with multiple
            dependencies.
          </p>
        </section>
        <section className={s.section} id="adaption-and-resilience">
          <h5 className={s['boxed-title']}>Adaptation and Resilience</h5>
          <p>
            The choice of relying on AI rather than classical automation to interact with web pages allows the AI Agents
            to adapt to small or big changes for known job application flows as well as completely new and unknown
            flows.
          </p>
          <p>
            SonicJobs proprietary technology, based on tactical and strategic reasoning coupled with error recovery,
            provides unmatched resilience and sets the standard in the Web AI Agents space.
          </p>
          <p>
            In addition, the unique architecture of the AI Agents, includes advances in Multi-Agents frameworks,
            Multimodal Models and Model Ensembles, allows for a rapid integration of new technological evolutions, such
            as those tied to LLMs and Foundational Models.
          </p>
        </section>
        <section className={s.section} id="vertical-design-with-generalizability">
          <h5 className={s['boxed-title']}>Vertical Design with Generalizability</h5>
          <p>
            For enterprise AI Agent solutions, the interconnectivity between the domain-specific application layer and
            the general-purpose reasoning layer needs to be very strong. This is because accuracy and reliability are
            very important for enterprises, particularly for mission-critical workflows. Consequently, the architecture
            of the Agents needs to be specific to the domain (e.g. jobs), as well as generalizable enough to be expanded
            to further domains.
          </p>
          <p>
            Our AI Agent technology is built for scale, with the core models targeted at capturing features that are
            common across websites (e.g. UI elements detection).
          </p>
          <p>
            We have built an ‘Adaptive Learning Flywheel,’ where each successfully completed task (e.g. job application
            on behalf of the user) within our system continuously improves the performance of our models. As more
            successful applications are completed by our AI Agents, the models are automatically refined and optimized,
            leading to higher accuracy and error resolution, which in turn leads to more successful applications and
            further enhances the system’s capabilities.
          </p>
        </section>
        <section className={s.section} id="future-direction">
          <h4 className={s.subtitle}>Future Direction</h4>
          <p>
            SonicJobs is building on the next multi-agent architecture for deeper long-form context tasks on the web by
            leveraging on the latest psychology-inspired AI concepts and leveraging on the power of LLMs, fine tuning
            and in-context learning, and will further improve the generalizability and scalability of our models.
          </p>
          <p>The AI Agent revolution is only just beginning, and SonicJobs is at the forefront of it.</p>
        </section>
      </div>
    </div>
  )
}

export default Info
