'use client'

import { FC } from 'react'

import { basePath } from '@lib/constants'
import * as Dialog from '@radix-ui/react-dialog'
import * as VisuallyHidden from '@radix-ui/react-visually-hidden'

import Play from './play'

import s from './video.module.scss'

import CrossIcon from '../cross-icon'

type VideoProps = {
  description: string
  video: string
  altText: string
}

const Video: FC<VideoProps> = ({ description, video, altText }) => {
  return (
    <div className={s.root}>
      <Dialog.Root>
        <Dialog.Trigger asChild className={s.trigger}>
          <button className={s['trigger-btn']}>
            <video
              className={s['image']}
              autoPlay
              loop
              muted
              playsInline
              poster={`${basePath}/videos/mini/${video}.jpg`}
            >
              <source src={`${basePath}/videos/mini/${video}.webm`} type="video/webm" />
              <source src={`${basePath}/videos/mini/${video}.mp4`} type="video/mp4" />
            </video>
            <div className={s.cta}>
              <Play />
              <p className={s.cta__text}>play video</p>
            </div>
          </button>
        </Dialog.Trigger>
        <Dialog.Portal>
          <Dialog.Overlay className={s.overlay} />
          <Dialog.Content className={s.content}>
            <div className={s['video-wrapper']}>
              <video className={s.video} controls controlsList="nodownload" playsInline preload="auto" autoPlay>
                <source src={`${basePath}/videos/${video}.webm`} type="video/webm" />
                <source src={`${basePath}/videos/${video}.mp4`} type="video/mp4" />
              </video>
            </div>
            <VisuallyHidden.Root>
              <Dialog.Title>{altText}</Dialog.Title>
            </VisuallyHidden.Root>
            <Dialog.Description className={s.title}>{description}</Dialog.Description>
            <Dialog.Close asChild>
              <button className={s['icon-button']} aria-label="Close">
                <CrossIcon className={s['close-icon']} />
              </button>
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </div>
  )
}

export default Video
