import {
  chatAGACongratulationsReceivedEvent,
  chatAcceptedTermsConditionsEvent,
  chatApplicationCompletedEvent,
  chatCVCreationCompletedEvent,
  chatCVCreationStartedEvent,
  chatCVUploadSuccessEvent,
  chatContactInformationSubmittedEvent,
  chatEducationInformationSubmittedEvent,
  chatEmailSetEvent,
  chatLegacyCVCreationStartedEvent,
  chatReplacedCVEvent,
  chatShowCustomQuestionsEvent,
  chatSimilarJobMessageToggleJobEvent,
  chatUploadCVEvent,
  chatUploadPhotosEvent,
  chatUploadedWrongCVExtensionEvent,
  chatWorkInformationSubmittedEvent,
} from './chat'
import { customerInsightCTAClickedEvent, customerInsightDismissedEvent } from './customer-insight'
import {
  emailDailyRecommendationsRedirectEvent,
  emailInstantJobMatchMoreJobsButtonClickedEvent,
  emailInstantJobMatchViewAllButtonClickedEvent,
} from './email'
import { faqAnotherIssueClickedEvent, faqOpenByIdleEvent, faqOpenEvent, faqQuestionOpenedEvent } from './faq'
import footerSearchJobsButtonEvent from './footer'
import { jobApplyButtonClickedEvent, jobInactiveJobEvent, jobPageViewEvent, jobUnknownJobEvent } from './job'
import { localeSelectorChangeLocationEvent, localeSelectorCloseEvent, localeSelectorShowEvent } from './locale-selector'
import {
  loginEmailSetEvent,
  loginOTPEnteredEvent,
  loginOTPSentAgainEvent,
  loginOTPSentEvent,
  loginResetPasswordEvent,
  loginSignInEvent,
  loginSignOutEvent,
  loginSignUpEvent,
} from './login'
import massApplyButtonClickEvent from './mass-apply'
import profileDeleteAccountEvent from './profile'
import { reminderOpenedEvent, reminderSetEvent } from './reminder'
import searchSubmittedEvent from './search'
import { serpFiltersOpenedEvent, serpFiltersUpdatedEvent, serpPageViewEvent } from './serp'

import {
  miscCVUploadVideoStartEvent,
  miscFailedToSetUserSourceEvent,
  miscPageViewEvent,
  miscRedirectToSearchPageEvent,
  miscSocialMediaLinkClickEvent,
  miscVideoPageViewEvent,
} from './misc'
import smsAppLinkSentEvent from './sms'

export {
  chatAGACongratulationsReceivedEvent, // web_accepted_TandCs
  chatAcceptedTermsConditionsEvent, // web_AGA_congratulation_message_seen
  chatApplicationCompletedEvent, // web_application_completed
  chatCVCreationCompletedEvent, // web_contact_information_complete
  chatCVCreationStartedEvent, // web_cv_creation
  chatCVUploadSuccessEvent, // web_cv_completed
  chatContactInformationSubmittedEvent, // web_correct_cv_upload
  chatEducationInformationSubmittedEvent, // web_last_education_info_complete
  chatEmailSetEvent, // web_aga_email_set
  chatLegacyCVCreationStartedEvent, // legacy_web_cv_started
  chatReplacedCVEvent, // replace_cv_upload
  chatShowCustomQuestionsEvent, // web_custom_questions_presented
  chatSimilarJobMessageToggleJobEvent, // Similar Job Message - Toggle suggestion
  chatUploadCVEvent, // web_upload_cv
  chatUploadPhotosEvent, // web_upload_photos
  chatUploadedWrongCVExtensionEvent, // web_wrong_cv_extension
  chatWorkInformationSubmittedEvent, // web_last_work_experience_info_complete
  customerInsightCTAClickedEvent, // web_customer_insight_cta_click
  customerInsightDismissedEvent, // web_customer_insight_dismissed
  emailDailyRecommendationsRedirectEvent, // Email:daily_recommendations:apply_button:{value}
  emailInstantJobMatchMoreJobsButtonClickedEvent, // Email:instant_job_match:more_jobs_like_this_button
  emailInstantJobMatchViewAllButtonClickedEvent, // Email:daily_recommendations:view_all_button:${value}
  faqAnotherIssueClickedEvent, // web_faq_another_issue
  faqOpenByIdleEvent, // web_faq_open:idle
  faqOpenEvent, // web_faq_open:click
  faqQuestionOpenedEvent, // web_open_faq_question
  footerSearchJobsButtonEvent, // Footer - Search Button Click
  jobApplyButtonClickedEvent, // web_apply_button_clicked
  jobInactiveJobEvent, // web_user_landed_on_inactive_job
  jobPageViewEvent, // web_job_ad_opened
  jobUnknownJobEvent, // web_user_landed_on_unknown_job
  localeSelectorChangeLocationEvent, // web_location_popup_cta_click
  localeSelectorCloseEvent, // web_close_location_popup
  localeSelectorShowEvent, // web_location_popup_display
  loginEmailSetEvent, // web_email_set
  loginOTPEnteredEvent, // web_otp_entered
  loginOTPSentAgainEvent, // web_otp_sent
  loginOTPSentEvent, // web_otp_sent_again
  loginResetPasswordEvent, // web_reset_password
  loginSignInEvent, // web_login
  loginSignOutEvent, // web_logout
  loginSignUpEvent, // web_sign_up
  massApplyButtonClickEvent, // web_apply_to_all_button_clicked
  miscCVUploadVideoStartEvent, // web_video_start:cv_upload
  miscFailedToSetUserSourceEvent, // web_failed_to_set_user_source
  miscPageViewEvent, // web_screen_open
  miscRedirectToSearchPageEvent, // web_from_login_to_search
  miscSocialMediaLinkClickEvent, // Social Media Link Click
  miscVideoPageViewEvent, // web_video_land
  profileDeleteAccountEvent, // web_delete_account
  reminderOpenedEvent, // web_job_reminder_modal_open
  reminderSetEvent, // web_job_reminder_set
  searchSubmittedEvent, // web_search_button_clicked
  serpFiltersOpenedEvent, // web_filter_open
  serpFiltersUpdatedEvent, // web_filter_changed
  serpPageViewEvent, // web_find_jobs_open
  smsAppLinkSentEvent, // web_sms_sent
}
